<template>
  <v-app-bar app class="appbar" clipped-left :class="classCordova">
    <v-container fluid class="d-flex flex-column pa-0">
      <v-row no-gutters :align="'center'" class="primary-navbar">
        <v-col
          cols="1"
          class="justify-left align-center"
          v-if="$routerHistory.hasPrevious()"
        >
          <!-- <v-btn
            :to="{ path: $routerHistory.previous().path }"
            class="no-border primary--text"
            aria-label="Torna Indietro"
            icon
          >
            <v-icon class="default--text">$prev</v-icon>
          </v-btn> -->

          <v-btn
            v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
            @click.prevent.stop="historyBack()"
            class="back-button no-border"
            aria-label="Torna Indietro"
            icon
          >
            <v-icon medium>$prev</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          md="2"
          lg="2"
          xl="1"
          class="justify-center align-center"
        >
          <!-- logo desktop -->
          <router-link to="/" class="px-0 d-none d-md-flex">
            <v-img
              src="@/assets/tosano.svg"
              alt="Logo Tosano"
              max-height="45px"
              max-width="150px"
              contain
            />
          </router-link>

          <!-- logo mobile -->
          <a class="px-0 d-flex d-md-none" @click.stop="openAddress">
            <v-img
              src="@/assets/tosano-mobile.svg"
              alt="Logo Tosano"
              height="45px"
              width="45px"
              contain
            />
          </a>
        </v-col>
        <v-col
          :cols="$routerHistory.hasPrevious() ? 8 : 9"
          :md="$routerHistory.hasPrevious() ? 8 : 9"
          class="d-flex flex-row justify-center align-center px-md-2 "
          style="flex-wrap: nowrap;"
        >
          <div
            style="min-width: 100px; max-width: 100%;"
            class="flex-grow-1 flex-shrink-0 pl-2"
          >
            <SearchInput />
          </div>
        </v-col>
        <v-col cols="1" md="1" class="d-flex justify-end">
          <!-- <CartInfoList
            v-if="isAuthenticated"
            class="d-none"
            :class="!isCordova && $vuetify.breakpoint.smAndUp ? 'd-md-block' : ''
            "
          />-->
          <v-btn
            icon
            large
            class="no-border"
            @click.stop="drawerLinks = !drawerLinks"
            style="right: 0px"
          >
            <v-icon large color="primary">$info</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex delivery-fee-container">
        <DeliveryFee
          :showList="false"
          :deliveryFee="deliveryFee"
          :optionClass="'navbar-delivery-fee'"
        />
      </div>
      <v-row
        no-gutters
        class="d-flex flex-nowrap links secondary align-center"
        :class="{ 'bar-shadow': barShadow && isMobile }"
        style="height: 40px;"
      >
        <!-- class="d-none d-md-flex links align-center secondary" -->
        <ProposalButtonsGrid :proposals="header" />
      </v-row>
      <v-progress-linear
        :active="showOverlay"
        :indeterminate="showOverlay"
        absolute
        bottom
        color="primary"
        class="progress-bar"
      ></v-progress-linear>
    </v-container>
  </v-app-bar>
</template>
<style lang="scss">
@media screen and (max-width: 1360px) {
  .username {
    display: none !important;
  }
}

.links {
  transform: translateY(0);
  transition: transform 300ms linear;
  &.bar-shadow {
    // transform: translateY(-100%);
    -webkit-box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 10px 20px 3px rgba(0, 0, 0, 0.6);
  }
  .swiper-slide {
    width: 100% !important;
  }
}

.v-main {
  background-color: $background-color-white;
  padding-top: calc(112px + env(safe-area-inset-top)) !important;
}
.v-app-bar--fixed {
  background-color: var(--v-white-base) !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  // iOS 11.0 fixes
  height: calc(
    #{$statusbarheightapp} + constant(safe-area-inset-top) + 14px
  ) !important; // % ($statusbarheight constant(safe-area-inset-top))
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$statusbarheightapp} + env(safe-area-inset-top) + 14px
  ) !important; // ($statusbarheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightapp;
    width: 100%;
    // iOS 11.0 fixes
    height: calc(
      #{$statusbarheightapp} + constant(safe-area-inset-top) + 14px
    ) !important;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    // iOS 11.2 fixes
    height: calc(
      #{$statusbarheightapp} + env(safe-area-inset-top) + 14px
    ) !important;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.v-navigation-drawer--fixed {
  position: fixed;
  z-index: 16 !important;
  height: $navigationheight;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$navigationheight} + env(safe-area-inset-top)
  ) !important; // ($navigationheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  @media #{map-get($display-breakpoints, 'md-and-down')} {
    top: 0 !important;
  }
  &.cart-drawer {
    height: calc(#{$navigationheight} + env(safe-area-inset-top)) !important;
  }
  &.categories {
    padding-bottom: 83px;
  }
}

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

.primary-navbar {
  padding: 15px 0 !important;
}

.v-toolbar__content {
  height: auto !important;
  padding: 0px !important;

  .v-btn--icon {
    border: 1px solid $primary;
    &.no-border {
      border: none;
    }
  }

  .v-badge__badge {
    color: $text-color;
  }

  .border {
    border: 1px solid $primary;
  }
}

.is-cordova {
  .progress-bar {
    bottom: 0;
  }
  .icon-info:before {
    font-size: 30px;
  }
  .delivery-fee {
    width: 100%;
    .navbar-delivery-fee {
      text-align: center;
    }
  }
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import ProposalButtonsGrid from "@/components/proposal/ProposalButtonsGrid.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";

import SearchInput from "@/components/navigation/SearchInput";
//import CartInfoList from "@/components/cart/CartInfoList";
import CategoryService from "@/commons/service/categoryService";
import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapState } from "vuex";
import { mapProposal } from "~/service/ebsn.js";

export default {
  name: "Navbar",
  mixins: [login, cartInfo],
  components: {
    SearchInput,
    //CartInfoList,
    DeliveryFee,
    ProposalButtonsGrid
  },
  data() {
    return {
      isMobile: false,
      navbarCategory: null
    };
  },
  props: {
    deliveryFee: { type: Object, required: false },
    barShadow: { type: Boolean, default: false }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async openAddress() {
      if (this.isAuthenticated) {
        await this.openAddressSelector();
      } else {
        await this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    },
    async loadNavbarCategory() {
      try {
        this.navbarCategory = await CategoryService.getCategoryBySlug(
          "navbar",
          false
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    ...mapProposal(
      {
        header: "header"
      },
      "navbarCategory"
    ),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  },
  async created() {
    this.loadNavbarCategory();
  }
};
</script>
